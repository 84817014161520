import React, {useState} from 'react';
import { Grommet, Box, Grid, Card, CardHeader, CardBody, Heading, Text, Anchor, CheckBox } from 'grommet';
import theme from '../../theme';
import { defaultProps } from 'grommet';

const normalizeColor = (color, theme, dark) => {
  const colorSpec = theme.global && theme.global.colors[color] !== undefined ? theme.global.colors[color] : color;
  // If the color has a light or dark object, use that
  let result = colorSpec;
  if (colorSpec) {
    if ((dark === true || (dark === undefined && theme.dark)) && colorSpec.dark !== undefined) {
      result = colorSpec.dark;
    } else if ((dark === false || !theme.dark) && colorSpec.light !== undefined) {
      result = colorSpec.light;
    }
  }
  // allow one level of indirection in color names
  if (result && theme.global && theme.global.colors[result] !== undefined) {
    result = normalizeColor(result, theme, dark);
  }

  return result;
};

const Typography = (props) => {
  const [checked, setChecked] = useState(false);

  return (
    <Grommet full theme={theme} themeMode={checked ? "dark" : "light"} background="background-back">
      <Box pad={{ horizontal: 'none', vertical: 'small' }} width={{ min: 'medium', max: 'large' }} margin="auto" direction="row" justify="between">
        <Heading level="1" style={{ fontWeight: 200 }}>
          Typography
        </Heading>
        <CheckBox
          {...props}
          label="Dark mode"
          checked={checked}
          onChange={(event) => setChecked(event.target.checked)}
          toggle
        />
      </Box>
      <Grid columns={['flex', ['medium', 'large'], 'flex']} rows={['flex']} areas={[{ name: 'content', start: [1, 0], end: [1, 0] }]}>
        <Box gridArea="content" background="background-front" pad="large">
          <Card round={false} margin={{ top: 'large' }}>
            <CardHeader pad={{ vertical: 'small', horizontal: 'medium' }}>
              <Heading level="2" style={{ fontWeight: 200 }} margin="none">
                Text contexts
              </Heading>
            </CardHeader>
            <CardBody pad={{ vertical: 'small', horizontal: 'medium' }}>
              {['text', 'text-weak', 'status-ok', 'status-warning', 'status-critical', 'status-disabled'].map((context) => (
                <Box key={context} direction="row" align="baseline" gap="medium" justify="between" margin={{ vertical: 'small' }}>
                  <Box>
                    <Text size="medium" color={context}>{`${context} text`}</Text>
                    <Text size="medium" weight="bold" color={context}>{`${context} bold text`}</Text>
                  </Box>
                  <Box flex={{ shrink: 0 }}>
                    <Text size="xsmall" color="text-weak">
                      Color: {normalizeColor(context, theme, false)}
                    </Text>
                  </Box>
                </Box>
              ))}
              <Box direction="row" align="baseline" gap="medium" justify="between" margin={{ vertical: 'small' }}>
                <Anchor href="#" label="Text link" />
                <Box>
                  <Text size="xsmall" color="text-weak">
                    Color: {normalizeColor(theme.anchor.color, theme, false)}
                  </Text>
                </Box>
              </Box>
            </CardBody>
          </Card>
          <Card round={false} margin={{ top: 'large' }}>
            <CardHeader pad={{ vertical: 'small', horizontal: 'medium' }}>
              <Heading level="2" style={{ fontWeight: 200 }} margin="none">
                Text variants
              </Heading>
            </CardHeader>
            <CardBody pad={{ vertical: 'small', horizontal: 'medium' }}>
              {['xxlarge', 'xlarge', 'large', 'medium', 'small', 'xsmall'].map((size) => (
                <Box key={size} direction="row" align="baseline" gap="medium" justify="between" margin={{ vertical: 'small' }}>
                  <Box>
                    <Text size={size}>{`${size} text`}</Text>
                    <Text size={size} weight="bold">{`${size} bold text`}</Text>
                    <Text size={size} style={{ fontStyle: 'italic' }}>{`${size} italic text`}</Text>
                    <Text size={size} style={{ textDecoration: 'underline' }}>{`${size} underlined text`}</Text>
                  </Box>
                  <Box alignSelf="start">
                    <Text size="xsmall" color="text-weak">
                      Font size: {theme.text[size].size}
                    </Text>
                    <Text size="xsmall" color="text-weak">
                      Line height: {theme.text[size].height}
                    </Text>
                  </Box>
                </Box>
              ))}
            </CardBody>
          </Card>
          <Card round={false} margin={{ top: 'large' }}>
            <CardHeader pad={{ vertical: 'small', horizontal: 'medium' }}>
              <Heading level="2" style={{ fontWeight: 200 }} margin="none">
                Headings
              </Heading>
            </CardHeader>
            <CardBody pad={{ vertical: 'small', horizontal: 'medium' }}>
              {[1, 2, 3, 4, 5, 6].map((level) => (
                <Box key={level} direction="row" align="baseline" gap="medium" justify="between">
                  <Heading level={level}>{`Heading ${level}`}</Heading>
                  <Box alignSelf="start">
                    <Text size="xsmall" color="text-weak">
                      Font size: {theme.heading.level[level].medium.size}
                    </Text>
                    <Text size="xsmall" color="text-weak">
                      Line height: {theme.heading.level[level].medium.height}
                    </Text>
                  </Box>
                </Box>
              ))}
            </CardBody>
          </Card>
          <Card round={false} margin={{ top: "large" }}>
            <CardHeader pad={{ vertical: "small", horizontal: "medium" }}>
              <Heading level="2" style={{ fontWeight: 200 }} margin="none">Margins and Padding</Heading>
            </CardHeader>
            <CardBody pad={{ top: "medium", bottom: "small", horizontal: "medium" }} align="center">
              {['xlarge', 'large', 'medium', 'small', 'xsmall', 'xxsmall', 'hair', 'none'].map((size) => (
                <Box key={size} margin={{bottom: "medium"}} background="#ffd6ad" border="all" width="fit-content">
                  <Box margin={size}>
                    <Box pad={size} border="all" background="#daeedb" align="center">
                      <Box background="background" border="all" pad="small" align="center">
                        <Text>Pad/margin size <Text weight="bold">{size}</Text></Text>
                        <Text size="xsmall" color="text-weak" margin={{top: "small"}}>{theme.global.edgeSize ? theme.global.edgeSize[size] : defaultProps.theme.global.edgeSize[size]}</Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
            </CardBody>
          </Card>
        </Box>
      </Grid>
    </Grommet>
  );
};

export default Typography;
